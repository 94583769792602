.dropdown13 {
  position: relative;
  display: inline-block;
}
/* .dropdown11 {
  position: relative;
  display: inline-block;
} */

.dropdownContent13 {
  display: none;
  position: absolute;
  background-color: #2b2b2b;
  color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  min-width: 80px; /* Adjust width for products */
  height: auto; /* Dynamic height based on content */
  /* text-align: left; */
}

.dropdown13:hover .dropdownContent13 {
  display: block;
}

.dropdownContent13 a {
  background-color: #2b2b2b;
  text-decoration: none;
  display: block;
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.dropdownContent13 a:hover {
  background-color: #0c71bc;
}

/* .dropdownContent a:hover {
  background-color: #212121;
  color: white;
} */

/* Main dropdown container */
.dropdown {
  position: relative;
  display: inline-block;
}

/* .dropdown11 {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
} */

/* Product items dropdown */
.dropdownContent {
  display: none;
  position: absolute;
  background-color: #2b2b2b;
  color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  min-width: 80px; /* Adjust width for products */
  height: auto; /* Dynamic height based on content */
  text-align: left;
}

/* Game items dropdown */
.dropdownContent2 {
  display: none;
  position: absolute;
  /* background-color: #2b2b2b; */
  color: white;
  /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
  min-width: 250px; /* Adjust width for games */
  height: auto; /* Dynamic height based on content */
  text-align: left;
  right: 100%; /* Position to the right of the button */
  top: 0;
}

/* Hover effects */
.dropdown11:hover .dropdownContent2 {
  display: block;
}

.dropdown11:hover {
  background: #0c71bc;
}

.dropdown:hover .dropdownContent {
  display: block;
}

.dropdownContent a:hover,
.dropdownContent2 a:hover {
  background-color: #0c71bc;
}

/* Link items inside dropdown */
.dropdownContent a,
.dropdownContent2 a {
  /* background-color: #212121; */
  text-decoration: none;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: left !important;
  /* padding: 10px 20px; Adjust padding to make it more clickable */
  color: white;
  align-items: flex-start;
}

/* Game images styling */
.dropdownContent2 img {
  width: 30px;
  margin-right: 10px;
}

/* Hover effect for links */
/* .dropdownContent a:hover,
.dropdownContent2 a:hover {
  background-color: #212121;
  color: white;
} */

/* Style for product items (without number) */
.dropdownContent a.productItem {
  width: 100%;
  height: 5px; /* Set height for product items */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 12px 20px; */
}
.dropdownContent a.gameItem:hover {
  background-color: #0c71bc;
}

/* Style for game items (with number) */
.dropdownContent2 a.gameItem {
  width: 100%;
  height: 50px; /* Set height for game items */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 12px 20px; */
}
