@import url(https://fonts.cdnfonts.com/css/sf-pro-display);
@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.ticker.error {
    color: #FE87A4;
}

.ticker.info {
    color: #59B5FF;
}

.ticker.success {
    color: #59D5AF;
}

.ticker.warning {
    color: #FEC21B;
}
.pinText::after {
    content: "";
    width: 32px;
    height: 4px;
    background: #0C71BC;

    position: absolute;
    bottom: -4px;
    left: 0px;
}

.dropdown13 {
  position: relative;
  display: inline-block;
}
/* .dropdown11 {
  position: relative;
  display: inline-block;
} */

.dropdownContent13 {
  display: none;
  position: absolute;
  background-color: #2b2b2b;
  color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  min-width: 80px; /* Adjust width for products */
  height: auto; /* Dynamic height based on content */
  /* text-align: left; */
}

.dropdown13:hover .dropdownContent13 {
  display: block;
}

.dropdownContent13 a {
  background-color: #2b2b2b;
  text-decoration: none;
  display: block;
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.dropdownContent13 a:hover {
  background-color: #0c71bc;
}

/* .dropdownContent a:hover {
  background-color: #212121;
  color: white;
} */

/* Main dropdown container */
.dropdown {
  position: relative;
  display: inline-block;
}

/* .dropdown11 {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
} */

/* Product items dropdown */
.dropdownContent {
  display: none;
  position: absolute;
  background-color: #2b2b2b;
  color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  min-width: 80px; /* Adjust width for products */
  height: auto; /* Dynamic height based on content */
  text-align: left;
}

/* Game items dropdown */
.dropdownContent2 {
  display: none;
  position: absolute;
  /* background-color: #2b2b2b; */
  color: white;
  /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
  min-width: 250px; /* Adjust width for games */
  height: auto; /* Dynamic height based on content */
  text-align: left;
  right: 100%; /* Position to the right of the button */
  top: 0;
}

/* Hover effects */
.dropdown11:hover .dropdownContent2 {
  display: block;
}

.dropdown11:hover {
  background: #0c71bc;
}

.dropdown:hover .dropdownContent {
  display: block;
}

.dropdownContent a:hover,
.dropdownContent2 a:hover {
  background-color: #0c71bc;
}

/* Link items inside dropdown */
.dropdownContent a,
.dropdownContent2 a {
  /* background-color: #212121; */
  text-decoration: none;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: left !important;
  /* padding: 10px 20px; Adjust padding to make it more clickable */
  color: white;
  align-items: flex-start;
}

/* Game images styling */
.dropdownContent2 img {
  width: 30px;
  margin-right: 10px;
}

/* Hover effect for links */
/* .dropdownContent a:hover,
.dropdownContent2 a:hover {
  background-color: #212121;
  color: white;
} */

/* Style for product items (without number) */
.dropdownContent a.productItem {
  width: 100%;
  height: 5px; /* Set height for product items */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 12px 20px; */
}
.dropdownContent a.gameItem:hover {
  background-color: #0c71bc;
}

/* Style for game items (with number) */
.dropdownContent2 a.gameItem {
  width: 100%;
  height: 50px; /* Set height for game items */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 12px 20px; */
}

.termsModal {
 display: flex;
 flex-direction: column;
 align-items: center;
 margin: 1.5rem;
}

.termsModal__body {
 margin-top: 1rem;
}

.userCameFrom {
 margin: 1rem;
}
.offer__dialog {
 margin: 1.5rem;
 text-align: center;
}

.modalHeader {
 display: flex;
 justify-content: space-around;
 text-align: center;
}

.modalBody {
 text-align: center;
 display: flex;
 flex-direction: column;
 align-items: center;
 grid-gap: 3rem;
 gap: 3rem;
}

.modalBody__img {
 width: 350px;
 /* height: 150px; */
 border-radius: 10px;
}

.modalBody__img > img {
    width: 100%;
    
}
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  padding: 0;
  margin: 0;
  font-family: "SF Pro Display", sans-serif;
}
/* Rubik, Source Sans Pro, Poppins, Arial,Helvetica */
body * {
  box-sizing: border-box;
}

.margin-0 {
  margin: 0;
}
.mt-46 {
  margin-top: 46px;
}
.mt-30 {
  margin-top: 30px;
}
.pl-10 {
  padding-left: 10px;
}

.krmNah {
  background-color: #0c71bc !important;
  box-shadow: 0 0 1px 3px rgb(12 113 188) !important;
}

.flex-clm {
  display: flex;
  flex-direction: column;
}
.day {
  background-color: rgb(167, 166, 166);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 1em;
  font-weight: 600;
  border-radius: 5px;
  color: white;
  text-align: center;
}
.hour {
  background-color: rgb(167, 166, 166);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 1em;
  font-weight: 600;
  border-radius: 5px;
  color: white;
  text-align: center;
}
.minute {
  background-color: rgb(167, 166, 166);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 1em;
  font-weight: 600;
  border-radius: 5px;
  color: white;
  text-align: center;
}
.second {
  background-color: rgb(167, 166, 166);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 1em;
  font-weight: 600;
  border-radius: 5px;
  color: white;
  text-align: center;
}
.span_class {
  font-size: 0.85em;
  font-weight: 400;
  color: white;
  text-align: center;
}

